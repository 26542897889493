import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import 'assets/styles/fonts/open-sans.css'
import 'assets/styles/fonts/circe.css'
import 'assets/styles/style.scss'
import css from './Main.module.scss'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Feedback from 'components/Feedback'
import WithFeedbackContext from 'containers/WithFeedbackContext'
import SchemaOrganization from 'components/Schema/Organization'
import Heading from 'components/Heading'
import ButtonCart from 'components/ButtonCart'
import Notifications from 'components/Notifications'
import NotificationsContext from 'contexts/notifications'
import Shopping from 'components/Shopping'
import ScrollToTop from 'components/ScrollToTop'

const b = bem.layoutMain(css)

const Main = ({ children, className, feedbackIsVisible, isPadding, title, description }) => {
	const notificationsContext = useContext(NotificationsContext)

	return (
		<div className={b({}, className)}>
			<SchemaOrganization />
			<div className={b('main')}>
				<Header />
				<div className={b('content')}>
					{title && <Heading title={title} description={description} />}
					<div className={b('inner', { 'is-padding': isPadding })}>{children}</div>
				</div>
			</div>
			<div>
				<Footer />
				<Feedback isVisible={feedbackIsVisible} />
				<ScrollToTop />
				{/*<Cookies />*/}
			</div>
			<div className={b('button-cart')}>
				<ButtonCart />
			</div>
			<Notifications items={notificationsContext.notifications} onRemove={notificationsContext.remove} />
			<Shopping
				title="Проведение акции"
				color="green"
				month={11}
				dayStart={11}
				dayFinish={19}
				promos={{
					2023: 'DBS2311',
					2024: 'DBS1124',
					2025: 'DB2511',
					2026: 'DB1126',
				}}
			/>
			<Shopping
				title="Черная пятница"
				color="black"
				month={11}
				dayStart={24}
				dayFinish={28}
				promos={{
					2023: 'BLACK2311',
					2024: 'BLACK1124',
					2025: 'BLACK2511',
					2026: 'BLACK1126',
				}}
			/>
			<Shopping
				title="Новогодняя акция"
				color="blue"
				month={1}
				dayStart={1}
				dayFinish={7}
				promos={{
					2024: 'NEW2024',
					2025: 'GO2025',
					2026: 'NEW2026',
					2027: 'GO2027',
				}}
			/>
		</div>
	)
}

Main.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	feedbackIsVisible: PropTypes.bool.isRequired,
	isPadding: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
}

export default WithFeedbackContext(Main)
